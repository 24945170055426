import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getSlider } from '../../api/apiHandler';
import { Link } from 'react-router-dom';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import './Home.css'

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


export default function Home() {

  const [activeHeader, setActiveHeader] = useState('home')

  const mainSliderOptions = {
    autoplay: true,
    arrows: true,
    loop: true,
    margin: 10,
    dots: true,
    nav: false,
    items: 1,
    navText: [
      "<span class='fas fa-chevron-left'></span>",
      "<span class='fas fa-chevron-right'></span>"
    ],
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    mouseDrag: false,
    autoplayTimeout: 6000,
    smartSpeed: 4000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const [sliderImage, setSliderImage] = useState(null)

  useEffect(() => {

    // console.log("hello")
    getSlider({}).then((res) => {
      // console.log("1 --",res.data.data[0].image.split(","))
      if (res.data.code == 1) {
        setSliderImage(res.data.data[0].image.split(","))
      } else {
        setSliderImage([])
      }
    })
  }, [])

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  if (sliderImage == null) return <> </>

  return (
    <>
      {/* <Header />
      {loading ? (
        <div className="loader-container" >
          <div className="loader-icon">
            <img src='/assets/images/logo.png' alt="" />
            <img src="/assets/images/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <> */}


      <div id="content">
        <div style={{ marginTop: "85px" }}>
          <div className="main-slider">
            <OwlCarousel id="home-slider" className="owl-theme owl-animated-out" {...mainSliderOptions}>
              {sliderImage.map((item, key) => {
                // console.log("----->",item)
                return (
                  <div className="item">
                    <img src={process.env.REACT_APP_Image_URL + item} alt="" />
                  </div>
                )
              })
              }
            </OwlCarousel>
          </div>
        </div>
        <div className="promos padding-top-50 padding-bottom-50">
          <div className="">
            <div className="container">
              <div className="col-md-6 emergency-text">
                <h4>Helpline : +91 281 2374726 (Mon - Sat : 9:00 to 19:00)</h4>
                <p className="text-white font-ubuntu">
                  CATALOGUE - SAR AUTO PRODUCTS LTD - RAJKOT (GUJARAT) INDIA
                </p>
              </div>

              <div className="col-md-3 text-right"><a href="assets/investor_relation/SAR-AUTO-PRODUCTS-LTD-BROCHURE.pdf" className="btn btn-blank margin-top-20" target="_new">Download Brochure</a> </div>

            </div>
          </div>
        </div>

        <section className="welcome padding-top-50 padding-bottom-100">
          <div className="container">
            <div className="heading blck text-center">
              <h3>Welcome to Sar Auto Products Limited </h3>
              <hr />
              <div className="text-justify">
                <p>Sar Auto Products Limited (SAPL) was incorporated as a Pvt. Ltd company on 27th October'1987 and was converted into a Public Company on 12th January'1996. The company engage in manufacturing and selling of Auto components, especially Gears for Transmission, Differential & Engine. SAPL manufactures Auto components comprising of automobile gears like Spur Gear, Helical Gear, Straight Bevel Gear, Sprockets along with Transmission Spline Shafts, Couplings and Power Take-off clutches which are used for Transmission, Engine and Differential gear boxes. The company came out with its maiden IPO in 1996 and currently company listed on Bombay Stocks Exchange.
                </p>
                <br /><br />
                <p align="center" className={activeHeader == "about" ? "dropdown active" : "dropdown"} onClick={() => { setActiveHeader('about') }} ><Link to="/who-we-are"><button className="btn">Read More</button></Link> </p>
              </div>

            </div>

          </div>
        </section>

        <section className="shipper">
          <div className="row no-margin inside ship">

            <div className="col-lg-6= col-md-6 col-sm-6 no-padding">
              <div className="padding-top-50 padding-bottom-50 text-center test">
                <Zoom>
                  <img src="assets/images/IATF - SAR.jpg" alt="" />
                </Zoom>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 no-padding ">
              <div className="padding-top-50 padding-bottom-50 text-center test resp">
                <Zoom>
                  <img src="assets/images/Certificate_new.jpg" alt="" />
                </Zoom>
              </div>
            </div>

            {/* <div className="col-lg-6 col-md-12 col-sm-12 no-padding">
              <div className="inside ship padding-top-200 padding-bottom-200 text-center">
                <h4>Machining Quality Control</h4>
                <p>Self-check in machining process includes 1st article inspection and in-process inspection <br />
                  (record the data of product every hour).</p>
                <a href="#" className="btn btn-blank margin-top-20">Our Quality</a> </div>
            </div> */}
          </div>
          {/* <div className="orr">OR</div> */}
        </section>
      </div>
      {/* <Footer /> */}
    </>
    //   )}
    // </>
  )
}
