import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Infrastructure from './pages/AboutUs/Infrastructure';
import WhoWeAre from './pages/AboutUs/WhoWeAre';
import Catalogues from './pages/Catalogues/Catalogues';
import ExchangeSubmission from './pages/Investors/ExchangeSubmission';
import NoticesOfMeeting from './pages/Investors/NoticesOfMeeting';
import CorporateGovernance from './pages/Investors/CorporateGovernance';
import CompanyInformation from './pages/Investors/CompanyInformation';
import CompanyPolicies from './pages/Investors/CompanyPolicies';
import AnnualReports from './pages/Investors/Financials/AnnualReports';
import QuarterlyResults from './pages/Investors/Financials/QuarterlyResults';
import ShareholdingPattern from './pages/Investors/Financials/ShareholdingPattern';
import AnnualReturns from './pages/Investors/Financials/AnnualReturns';
import E_VotingResults from './pages/Investors/Financials/E_VotingResults';
import NewspaperPublication from './pages/Investors/Financials/NewspaperPublication';
import Quality from './pages/Quality/Quality';
import ContactUs from './pages/Contacts/Contacts';
import Product from './pages/Product/Product';
import Search from './pages/Search/Search';

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/who-we-are" element={<WhoWeAre />} />
            <Route path="/infrastructure" element={<Infrastructure />} />
            <Route path="/catalogues" element={<Catalogues />} />
            <Route path="/exchange-submission" element={<ExchangeSubmission />} />
            <Route path="/notices-of-meeting" element={<NoticesOfMeeting />} />
            <Route path="/corporate-governance" element={<CorporateGovernance />} />
            <Route path="/company-information" element={<CompanyInformation />} />
            <Route path="/company-policies" element={<CompanyPolicies />} />
            <Route path="/annual-reports" element={<AnnualReports />} />
            <Route path="/quarterly-results" element={<QuarterlyResults />} />
            <Route path="/shareholding-pattern" element={<ShareholdingPattern />} />
            <Route path="/annual-returns" element={<AnnualReturns />} />
            <Route path="/e-voting-results" element={<E_VotingResults />} />
            <Route path="/news-papers-publication" element={<NewspaperPublication />} />
            <Route path="/quality" element={<Quality />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/search/:text" element={<Search />} />
            <Route path="/contactus" element={<ContactUs />} />
          </Routes>
       
        < Footer />
      </BrowserRouter>
    </div>
  )
}

export default App;