import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCategory } from '../../api/apiHandler';

import { GiHamburgerMenu } from 'react-icons/gi'

import './Header.css'

export default function Header() {

  function handleKeyPress(e) {
    var key = e.key;
    // console.log("You pressed a key: " + key);
    if (key === "Enter") {
      search();
    }
  }

  const navigate = useNavigate();
  const [activeHeader, setActiveHeader] = useState(null)
  const [categoryList, setCategoryList] = useState(null)
  const [scrolled, setScrolled] = useState(null)

  useEffect(() => {
    getCategory({}).then((res) => {
      // console.log("1 --",res.data.data)
      if (res.data.code == 1) {
        setCategoryList(res.data.data)
      } else {
        setCategoryList([])
      }
    })
  }, [])

  useEffect(() => {
    let headerUrl = window.location.href.split("/")
    // console.log("url ------------->", headerUrl[3])
    if (headerUrl[3] == "") {
      setActiveHeader('home')
    }

    if (headerUrl[3] == "who-we-are" || headerUrl[3] == "infrastructure") {
      setActiveHeader('about')
    }

    if (headerUrl[3] == "company-information" || headerUrl[3] == "annual-reports" || headerUrl[3] == "quarterly-results" || headerUrl[3] == "shareholding-pattern" || headerUrl[3] == "annual-returns" || headerUrl[3] == "e-voting-results" || headerUrl[3] == "news-papers-publication" || headerUrl[3] == "notices-of-meeting" || headerUrl[3] == "company-policies" || headerUrl[3] == "corporate-governance" || headerUrl[3] == "exchange-submission") {
      setActiveHeader('investor')
    }

    if (headerUrl[3] == "product") {
      setActiveHeader('product')
    }

    if (headerUrl[3] == "catalogues") {
      setActiveHeader('catalogues')
    }

    if (headerUrl[3] == "quality") {
      setActiveHeader('quality')
    }

    if (headerUrl[3] == "contactus") {
      setActiveHeader('contact')
    }

  }, [activeHeader])

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector('#myHeader');
    const scrollTop = window.scrollY;
    scrollTop >= 200 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  function search() {
    var searchText = document.getElementById("gsearch").value

    if (searchText.trimStart().trimEnd().length > 0) {
      setActiveHeader('search')
      navigate(`/search/${searchText.trimStart().trimEnd()}`);
    }
    // console.log("searchText",searchText.trimStart().trimEnd(),searchText.trimStart().trimEnd().length)
  }

  // const box = document.getElementById('testproduct')
  // if (box) {
  //   box.addEventListener('scroll', () => {
  //     console.log("IIII")
  //     if (box.scrollTop) {
  //       setScrolled("koko")
  //     }
  //     else {
  //       setScrolled(null)
  //     }

  //     //document.getElementById("testproduct").style.backgroundColor = "red";

  //     // const vertical = box.scrollTop   // Getting vertical scrollbar position of a div
  //     // const horizontal = box.scrollLeft    // Getting vertical scrollbar position of a div
  //     // // alert(`Vertical scrollbar Position of a div: ${vertical}`)
  //     // // alert(`Horizontal scrollbar Position of a div: ${horizontal}`)
  //     // console.log(`Vertical scrollbar Position of a div: ${vertical}`)
  //     // console.log(`Horizontal scrollbar Position of a div: ${horizontal}`)
  //     // // document.getElementById("product-three").style.marginTop = "-294px !important";
  //     // // if(vertical>30)
  //     // // {
  //     // //   document.getElementById("product-three").style.color = "red";
  //     // // }

  //   })
  // }
  // console.log(scrolled, "SCROLLPP");


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    };
  }, []);

  function aboutUsUrl(url) {
    // console.log("------------>",url)
    setActiveHeader('about')
    let path = window.location.protocol + "//" + window.location.host + url
    window.location.href = path;
  }

  function investorUrl(url) {
    // console.log("------------>",url)
    setActiveHeader('investor')
    let path = window.location.protocol + "//" + window.location.host + url
    window.location.href = path;
  }

  function productUrl(url) {
    // console.log("------------>>>",url)
    setActiveHeader('product')
    let path = window.location.protocol + "//" + window.location.host + url
    window.location.href = path;
  }
  // function changeCss() {
  //   var bodyElement = document.querySelector("body");
  //   var navElement = document.querySelector("nav");
  //   this.scrollY > 500 ? navElement.style.opacity = .8 : navElement.style.opacity = 1;
  // }

  // window.addEventListener("scroll", changeCss, false);

  if (categoryList == null) return <> </>
  return (
    <>
      {/* <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
      <a class="navbar-brand text-info font-weight-bolder" href="/">
        <img src="assets/images/logo.png" alt="Logo" width="36" height="36" className="vertical-align-middle" />
        <span className="">Discounter</span>
      </a>
      <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
        <a className="nav-link text-info" href="/contact">Support</a>
        <a className="nav-link text-info" href="/login">Login</a>
        <a href="/request-demo" className="btn btn-sm btn-info nav-link text-white" >Request demo</a>
      </div>
    </nav> */}

      <header>

        <div class="top-bar">
          <div class="top-info">
            <div class="container tstop">
              <ul className="social">
                <div id="google_box">
                  <div id="google_translate_element"></div>
                </div>
              </ul>
              <div className="quotes">
                {/* <input type="search" className='search' id="gsearch" name="gsearch" placeholder='Enter product' onKeyUp={search} /> */}
                <input type="search" className='search sech' id="gsearch" name="gsearch" placeholder='Enter product' onKeyPress={(e) => handleKeyPress(e)} />
                <input type="submit" className='btn1' value="Search" onClick={search} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {/* <div className="logo"> <Link to="index.html">
            <img className="img-responsive" src="assets/images/logo.png" alt="" /></Link>
          </div> */}

          <div className="logo">
            <div className={activeHeader == "home" ? "active" : ""} onClick={() => { setActiveHeader('home') }}> <Link to="/"><img className="img-responsive" src="https://sarautoproductsltd.com/assets/images/logo.png" alt="" /></Link> </div>
          </div>

          <div className="top-info-con hidden-xs">
            <ul>

              <li className="fst">
                <div className="media-left">
                  <a href="https://goo.gl/maps/VofdHJpTuapGSRGZ8" target="_blank">
                    <div className="icon"> <i className="fa fa-map-marker"></i></div>
                  </a>
                </div>
                <div className="media-body">
                  <h6 className="font-raleway">Our Location </h6>
                  <a href="https://goo.gl/maps/VofdHJpTuapGSRGZ8" target="_blank">Rajkot - 360 002 Gujarat - India.  </a> </div>
              </li>

              <li>
                <div className="media-left">
                  <a href='tel:+91-281-2374726'>
                    <div className="icon"><i className="fa fa-phone"></i></div>
                  </a>
                </div>
                <div className="media-body">
                  <h6>Mon - Sat : 9:00 to 19:00 </h6>
                  <a href="tel:+91-281-2374726">+91-281-2374726 </a> </div>
              </li>

              <li>
                <div className="media-left">
                  <a href="mailto:sapl@sarautoproductsltd.com">
                    <div className="icon"> <i className="fa fa-envelope"></i></div>
                  </a>
                </div>
                <div className="media-body">
                  <h6>mail us for quotes </h6>
                  <a href="mailto:sapl@sarautoproductsltd.com">sapl@sarautoproductsltd.com</a> </div>
              </li>
            </ul>
          </div>
        </div>





        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar"><GiHamburgerMenu /></label>

        <nav className="navbar header-section" id="myHeader">
          <div className="container">
            <ul className="nav ownmenu">
              {/* <li className="active"> <Link to="/">Home</Link> </li> */}
              <li className={activeHeader == "home" ? "active" : ""} onClick={() => { setActiveHeader('home') }}> <a href="/">Home</a> </li>


              <li className={activeHeader == "about" ? "dropdown active" : "dropdown"}> <Link to="#" className="dropdown-toggle" data-toggle="dropdown">About Us </Link>
                <ul className="dropdown-menu">
                  <li onClick={() => { aboutUsUrl('/who-we-are') }} ><Link to="">Who we are </Link></li>

                  {/* <li onClick={() => { aboutUsUrl('/infrastructure') }} ><Link to="">Infrastructure</Link></li> */}
                </ul>
              </li>

              {/* <li> <Link to="/catalogues">Catalogue</Link> </li> */}


              <li className={activeHeader == "investor" ? "dropdown active" : "dropdown"}> <Link to="#" className="dropdown-toggle" data-toggle="dropdown">Investors</Link>
                <ul className="dropdown-menu">
                  <li onClick={() => { investorUrl('/company-information') }}><Link to="">Company Information </Link></li>
                  <li ><Link to="">Financials</Link>
                    <ul className="dropdown-two">
                      <li onClick={() => { investorUrl('/annual-reports') }}><Link to="">Annual Reports </Link></li>
                      <li onClick={() => { investorUrl('/quarterly-results') }}><Link to="">Quarterly Results</Link></li>
                      <li onClick={() => { investorUrl('/shareholding-pattern') }}><Link to="">Shareholding Pattern</Link></li>
                      <li onClick={() => { investorUrl('/annual-returns') }}><Link to="">Annual Returns</Link></li>
                      <li onClick={() => { investorUrl('/e-voting-results') }}><Link to="">E-Voting Results</Link></li>
                      <li onClick={() => { investorUrl('/e-voting-results') }}><Link to="">Scrutinizer Reports</Link></li>
                      <li onClick={() => { investorUrl('/news-papers-publication') }}><Link to="">Newspaper Publication</Link></li>
                    </ul>
                  </li>
                  <li onClick={() => { investorUrl('/notices-of-meeting') }}><Link to="">Notices of Meeting</Link></li>
                  <li onClick={() => { investorUrl('/assets/investor_relation/CODE_OF_CONDUCT_SAPL.pdf') }}><Link to="" target="_blank">Code of Conduct</Link></li>
                  <li onClick={() => { investorUrl('/company-policies') }}><Link to="">Company Policies </Link></li>
                  <li onClick={() => { investorUrl('/corporate-governance') }}><Link to="">Corporate Governance</Link></li>
                  <li onClick={() => { investorUrl('/exchange-submission') }}><Link to="">Exchange Submission</Link></li>
                </ul>
              </li>

              <li className={activeHeader == "product" ? "dropdown active" : "dropdown "} > <Link to="#" className="dropdown-toggle blink " data-toggle="dropdown">Products</Link>
                <ul className="dropdown-menu scroll add-product " id="testproduct" >
                  {/* onClick={() => { productUrl("/product/" + item.id) }} */}
                  {categoryList.map((item, index) => {
                    return (
                      <li ><Link to="">{item.name} </Link>
                        <ul className="dropdown-three product-three" id="test3 " style={{ marginTop: scrolled == "koko" ? "-294px" : "-40px" }}>
                          {item.subCategory.map((sub, index) => {
                            // console.log("------------>",sub)
                            return (
                              <li onClick={() => { productUrl("/product/" + sub.id) }}><Link to="">{sub.name} </Link></li>
                            )
                          })
                          }
                        </ul>
                      </li>
                    )
                  })
                  }
                </ul>
              </li>

              <li className={activeHeader == "catalogues" ? "active" : ""} onClick={() => { setActiveHeader('catalogues') }}> <a href="/catalogues">Catalogue</a> </li>

              {/* <li> <Link to="/quality">Quality</Link> </li>
            <li> <Link to="/contactus">Contact Us</Link> </li> */}
              <li className={activeHeader == "quality" ? "active" : ""} onClick={() => { setActiveHeader('quality') }}> <a href="/quality">Quality</a> </li>
              <li className={activeHeader == "contact" ? "active" : ""} onClick={() => { setActiveHeader('contact') }}> <a href="/contactus">Contact Us</a> </li>
            </ul>
            {/* <form action="/action_page.php"> */}
            {/* <label for="gsearch">Search Google:</label>? */}

            {/* </form> */}
            <div className="quotes" onClick={() => { setActiveHeader('contact') }}> <Link to="/contactus" className="customcolor"> Enquire Now </Link> </div>


          </div>
        </nav>
      </header >




      {/* <button type="submit" class="button">Click here!</button> */}


    </>
  )
}



