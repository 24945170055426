import { axiosClient } from "./apiClient";

export function getSlider(data){
    return axiosClient.post('getSlider', data);
}
export function getCategory(data){
    return axiosClient.post('getCategory', data);
}
export function contactUs(data){
    return axiosClient.post('contactUs', data);
}
export function getProduct(data){
    return axiosClient.post('getProduct', data);
}
export function getAllProduct(data){
    return axiosClient.post('getAllProduct', data);
}
export function productSearch(data){
    return axiosClient.post('productSearch', data);
}