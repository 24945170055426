import React from 'react';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <footer>
                <div className="container padding-top-30">

                    <ul className="row">

                        <li className="col-md-4">
                            <h5>About us</h5>
                            <hr />
                            <p align="justify">Sar Auto Products Limited (SAPL) was incorporated as a Pvt. Ltd company on 27th October'1987 and was converted into a Public Company on 12th January'1996.<a href="/who-we-are">More...</a> </p>

                            {/* <ul className="social_icons">
                    <li className="facebook"> <a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i> </a></li>
                    <li className="twitter"> <a href="https://twitter.com/?lang=en" target="_blank"><i className="fa fa-twitter"></i> </a></li>
                    <li className="linkedin"> <a href="https://in.linkedin.com/" target="_blank"><i className="fa fa-linkedin"></i> </a></li>
                    <li className="tumblr"> <a href="https://www.tumblr.com/" target="_blank"><i className="fa fa-tumblr"></i> </a></li>
                </ul> */}
                        </li>


                        <li className="col-md-4">
                            <h5>QUICK LINKS</h5>
                            <hr />
                            <ul className="tags">
                                <li><a href="/">Home </a></li>
                                <li><a href="/who-we-are">About Us </a></li>
                                {/* <li><a href="/who-we-are">Product </a></li> */}
                                {/* <li><Link to="/infrastructure">Infrastructure </Link></li> */}
                                <li><a href="/quality">Quality </a></li>
                                <li><a href="/contactus">Contact Us </a></li>
                            </ul>
                        </li>

                        <li className="col-md-4">

                            <h5>GET IN TOUCH</h5>
                            <hr />
                            <div className="loc-info">
                                <a href="https://goo.gl/maps/VofdHJpTuapGSRGZ8" target="_blank"><p><i className="fa fa-map-marker"></i>
                                    50 / E, Bhaktinagar Industrial Estate,<br />
                                    Rajkot - 360 002, Gujarat, INDIA. </p></a>
                                <a href="tel:+91-9824459021"><p><i className="fa fa-mobile"></i> +91-98244 59021</p></a>
                                <a href='tel:+91-281-2374726'><p><i className="fa fa-phone"></i>  +91-281-2374726</p></a>
                                <a href="mailto:sapl@sarautoproductsltd.com"><p><i className="fa fa-envelope-o"></i>    sapl@sarautoproductsltd.com </p></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </footer>

            <div className="rights">
                <div className="container">
                    <div className="col-md-12">
                        <p className="font-montserrat">Copyright &copy; 2007 SAR Auto Products Ltd – Automobile Gears Manufacturer in Rajkot (Gujarat) - India, All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
