import React from 'react'

const AnnualReports = () => {
  return (
    <div id="content">

    <section className="welcome padding-top-50 padding-bottom-50">
      <div className="container">

			<div className="heading blck text-center">
			<h3>Annual Reports</h3>
			<hr/>
			</div>
			<div className="col-md-4"></div>
		   <div className="col-md-4">
				<table className="table table-bordered table-hover table-striped annualth">
				<tbody>
			

				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2008-2009.pdf" target="_blank">2008-09</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2009-2010.pdf" target="_blank">2009-10</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2010-2011.pdf" target="_blank">2010-11</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2011-2012.pdf" target="_blank">2011-12</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2012-2013.pdf" target="_blank">2012-13</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2013-2014.pdf" target="_blank">2013-14</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2014-2015.pdf" target="_blank">2014-15</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2015-2016.pdf" target="_blank">2015-16</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2016-2017.pdf" target="_blank">2016-17</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2017-2018.pdf" target="_blank">2017-18</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2018-2019.pdf" target="_blank">2018-19</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2019-2020.pdf" target="_blank">2019-20</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2020-2021.pdf" target="_blank">2020-21</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2021-2022.pdf" target="_blank">2021-22</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2022-2023.pdf" target="_blank">2022-23</a></th></tr>
				<tr><th><a href="assets/investor_relation/financial_results/Annual Report 2023-2024.pdf" target="_blank">2023-24</a></th></tr>

				</tbody>
				</table>
         </div>

           </div>

    </section>

  </div>
  )
}

export default AnnualReports
