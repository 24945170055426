import axios from "axios";

const showMessage = (msg) => {
  // console.log(msg)
}

const axiosClient = axios.create({
  baseURL: 'https://'+process.env.REACT_APP_API_URL+':8081/api/v1/user/',
  // baseURL: 'http://localhost:3016/api/v1/user/',
  headers : {
    'api-key' : 'ab6us9khd9ks5gn9kh3uiopw7sjk2ib5nj8kdtcng7hnc5dmkh7s4crt7yhgbt6d',
    'accept-language' : 'en',
    'Content-Type' : 'application/json',
  }
});

  // Body Encryption Request
  axiosClient.interceptors.request.use(function (request) {

     if(localStorage.getItem("Atoken") !== undefined || localStorage.getItem("Atoken") !== null){
       request.headers['token']=localStorage.getItem("Atoken")
      // console.log("Final Config",)
     }
    return request;
  });
  
  axiosClient.interceptors.response.use(
    function (response) {
      if(response.data != null){
        if(response.code==0){
          showMessage(response.data.message)
        }
      }
      return response;
    }, 
    function (error) {
      let res = error.response;
     
      if (res.status == 401) {
        // logOutRedirectCall()
      }
      console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
  );

  export {axiosClient};