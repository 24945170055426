import React from 'react'

const E_VotingResults = () => {
  return (
    <div id="content">

    <section className="welcome padding-top-50 padding-bottom-50">
      <div className="container">

			<div className="heading blck text-center">
			<h3>E-Voting Results & Scrutinizer Reports</h3>
			<hr/>
			</div>
			<div className="col-md-4"></div>
		   <div className="col-md-4">
				<table className="table table-bordered table-hover table-striped annualth">
				<tbody>
			{/* <!--	<tr><h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Financial Year</h3></tr>
				<tr><th>2008-09</th><th>2009-10</th>--> */}
				<tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2023.pdf" target="_blank">Sep-2023</a></th></tr>
				<tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2022.pdf" target="_blank">Sep-2022</a></th></tr>
				<tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2021.pdf" target="_blank">Sep-2021</a></th></tr>
                <tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2020.pdf" target="_blank">Sep-2020</a></th></tr>
                <tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2017.pdf" target="_blank">Sep-2017</a></th></tr>
				<tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2016.pdf" target="_blank">Sep-2016</a></th></tr>
				<tr><th><a href="assets/investor_relation/E-Voting_Result_Scrutinizer_Report_sep2015.pdf" target="_blank">Sep-2015</a></th></tr>


				</tbody>
				</table>
         </div>
         <div className="col-md-4"></div>

           </div>

    </section>

  </div>
  )
}

export default E_VotingResults
