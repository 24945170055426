import React, { useEffect, useState } from 'react'
import { contactUs } from '../../api/apiHandler';
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2';
import './Contacts.css'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


const Contacts = () => {

  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  function onSubmit() {
    var name = document.getElementById("firstname").value
    var email = document.getElementById("email").value
    var mobile_number = document.getElementById("phoneNumber").value
    var message = document.getElementById("message").value
    // console.log('onSubmit',name,)
    // if(name.trimStart().trimEnd().length == 0){
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'warning',
    //     title: 'Please enter name',
    //     toast: true,
    //     showConfirmButton: false,
    //     timer: 2000
    //   })
    // }else if(email.trimStart().trimEnd().length == 0){
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'warning',
    //     title: 'Please enter email',
    //     toast: true,
    //     showConfirmButton: false,
    //     timer: 2000
    //   })
    // }else if(mobile_number.trimStart().trimEnd().length == 0){
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'warning',
    //     title: 'Please enter phone',
    //     toast: true,
    //     showConfirmButton: false,
    //     timer: 2000
    //   })
    // }else if(message.trimStart().trimEnd().length == 0){
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'warning',
    //     title: 'Please enter message',
    //     toast: true,
    //     showConfirmButton: false,
    //     timer: 2000
    //   })
    // }else{

    // }

    contactUs({ "name": name, "email": email, "mobile_number": mobile_number, "message": message }).then((response) => {
      console.log("-------->",response)
      if (response.data.code == 1) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
        navigate('/')
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: response.data.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })


  }
  return (
    <>
      {/* <Header /> */}
      {/* {loading ? (
        <div className="loader-container" >
          <div className="loader-icon">
            <img src='/assets/images/logo.png' alt="" />
            <img src="/assets/images/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <> */}
      <section className="sub-bnr" style={{ background: "url(images/sub-bnr-bg.jpg) no-repeat;" }}>
        <div className="position-center-center">
          <div className="container">
            <h4>Contact Us</h4>
          </div>
        </div>
        <div className="bread">
          <div className="container">
            <h6>Welcome to SAR Auto Products Ltd </h6>
          </div>
        </div>
      </section>


      <div id="content">

        <section className="contact-page padding-top-0 padding-bottom-100">



          <div className="container">
            <div className="row margin-top-30 margin-bottom-30">
              <div className="col-md-7">
                <div className="heading text-left margin-bottom-10">
                  <h3> Leave A Message</h3>
                </div>
                <div className="contact-form">

                  <div className="margin-top-30">
                    <div className="contact-form">

                      <div id="contact_message" className="success-msg">
                        <i className="fa fa-paper-plane-o"></i>Thank You. Your Message has been Submitted</div>

                      <form >
                        <ul className="row">
                          <li className="col-sm-12">
                            <label>
                              <input type="text" id="firstname" name="firstname" className="form-control form-error-label" placeholder="Name" />
                            </label>
                          </li>
                          <li className="col-sm-12">
                            <label>
                              <input type="email" className="form-control" name="email" id="email" placeholder="E-Mail" />
                            </label>
                          </li>
                          <li className="col-sm-12">
                            <label>
                              <input type="tel" className="form-control" name="phone" id="phoneNumber" placeholder="Phone" maxLength="10" onInput={maxLengthCheck} />
                            </label>
                          </li>
                          <li className="col-sm-12">
                            <label>
                              <textarea className="form-control" name="message" id="message" rows="5" placeholder="Your Message"></textarea>
                            </label>
                          </li>
                          <li className="col-sm-12">
                            <label>
                              <div className="g-recaptcha brochure__form__captcha" data-sitekey="6LePpR8UAAAAAB-nerMFlBIyC-1KzE0syaWiUCgl"></div>
                            </label>
                          </li>
                          <li className="col-sm-12 cont-btn">
                            <div className="btn" onClick={onSubmit}>send Message</div>
                          </li>
                        </ul>
                      </form>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="cont-info">
                  <ul className="row">

                    <li className="col-md-12">
                      <article>
                        <div className="media-left">
                          <div className="icon"><i className="fa fa-map-o"></i></div>
                        </div>
                        <div className="media-body">
                          <p><a href="#">50 / E, Bhaktinagar Industrial Estate,<br />
                            RAJKOT - 360 002 Gujarat, INDIA.	</a>
                          </p>
                        </div>
                      </article>
                    </li>

                    <li className="col-md-12">
                      <article>
                        <div className="media-left">
                          <div className="icon"><i className="fa fa-mobile-phone"></i></div>
                        </div>
                        <div className="media-body">
                          <p> <a href="tel:+91-9824459021">+91-9824459021</a></p>
                        </div>
                      </article>
                    </li>

                    <li className="col-md-12">
                      <article>
                        <div className="media-left">
                          <div className="icon"><i className="fa fa-phone"></i></div>
                        </div>
                        <div className="media-body">
                          <p> <a href="tel:+91-281-2374726">+91-281-2374726</a></p>
                        </div>
                      </article>
                    </li>

                    {/* <li className="col-md-12">
                      <article>
                        <div className="media-left">
                          <div className="icon"><i className="fa fa-fax"></i></div>
                        </div>
                        <div className="media-body">
                          <p> <a href="tel:+91-281-2376806">+91-281-2376806</a></p>
                        </div>
                      </article>
                    </li> */}

                    <li className="col-md-12">
                      <article>
                        <div className="media-left">
                          <div className="icon"><i className="fa fa-envelope-o"></i></div>
                        </div>
                        <div className="media-body">
                          <p><a href="mailto:sapl@sarautoproductsltd.com">sapl@sarautoproductsltd.com</a></p>
                        </div>
                      </article>
                    </li>
                  </ul>
                </div>
              </div>

            </div>


            <div className="col-md-12 margin-bottom-50 row">

              <div className="col-md-5">
                <div className="row">
                  <div className="timing">
                    <ul>
                      <h3> Work Timing</h3>
                      <li>
                        <p>Monday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Tuesday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Wednesday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Thursday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Friday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Saturday <span>09 : 00 am  -  07 : 00 pm</span></p>
                      </li>
                      <li>
                        <p>Sunday <span>CLOSED</span></p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-7 cont-map">
                <div id="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7383.981979053412!2d70.7977756715319!3d22.278331157992827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSAR+Auto+Products+Ltd!5e0!3m2!1sen!2sin!4v1531257985371" width="100%" height="100%" style={{ border: "0" }} allowFullScreen></iframe>
                </div>

              </div>

            </div>

          </div>
        </section>

      </div>
      {/* <Footer /> */}
      {/* </>
      )} */}
    </>
  )
}

export default Contacts
