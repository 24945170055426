import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './NoticesOfMeeting.css'

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';



export default function NoticesOfMeeting() {
  return (
    <>
      <div id="content">
        <section className="welcome padding-top-50 padding-bottom-50">
          <div className="container">
            <div className="heading blck">
              <div className="text-center">
                <h3>AGM & EGM Proceedings</h3>
                <hr />
              </div>

              <ul className="dwbutton">
                <li>
                  <a href="assets/investor_relation/meetings/Appointment-of-Director.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Appointment of Director</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/Outcome-BM-09-Aug-23.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Outcome of Board Meeting Dtd 09th Aug. 2023</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/Outcome-BM-29-Oct-21.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Outcome of Board Meeting Dtd 29th Oct. 2021</a>
                </li>

                <li>
                  <a href="assets/investor_relation/meetings/AGM-Notice-34th.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>34th AGM Notice</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Notice-35th.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>35th AGM Notice</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/Notice of 36th AGM SAR 2023.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>36th AGM Notice</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2017.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2017</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2018.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2018</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2019.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2019</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2020.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2020</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2021.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2021</a>
                </li>
                <li>
                  <a href="assets/investor_relation/meetings/AGM-Proceedings-2022.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>AGM Proceedings 2022</a>
                </li>

              </ul>
            </div>
          </div>
        </section>

        <section className="welcome padding-top-50 padding-bottom-50">
          <div className="container">
            <div className="heading blck">
              <div className="text-center">
                <h3>Board Meeting Notice</h3>
                <hr />
              </div>

              <div className="col-md-offset-2 col-md-8">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                  <Accordion className="panel-group" id="accordion" role="tablist" preExpanded={['a']} allowZeroExpanded>
                    <div className="panel panel-default">
                      <AccordionItem className="panel-collapse collapse in" role="tabpanel" uuid="a">
                        <AccordionItemHeading className="panel-heading" role="tab">
                          <AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true">
                            2022-23
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="panel-body">
                          <ul className="dwbutton">
                            <li>
                              <a href="assets/investor_relation/meetings/2022-23-BM1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 1</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2022-23-BM2.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 2</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2022-23-BM3.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 3</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2022-23-BM4.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 4</a>
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                    <div className="panel panel-default">
                      <AccordionItem className="panel-collapse collapse in" role="tabpanel">
                        <AccordionItemHeading className="panel-heading" role="tab">
                          <AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false">
                            2021-22
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="panel-body">
                          <ul className="dwbutton">
                            <li>
                              <a href="assets/investor_relation/meetings/2021-22-BM1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 1</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2021-22-BM2.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 2</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2021-22-BM3.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 3</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2021-22-BM4.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 4</a>
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                    <div className="panel panel-default">
                      <AccordionItem className="panel-collapse collapse in" role="tabpanel">
                        <AccordionItemHeading className="panel-heading" role="tab">
                          <AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false">
                            2020-2021
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="panel-body">
                          <ul className="dwbutton">
                            <li>
                              <a href="assets/investor_relation/meetings/2020-21-BM1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 1</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2020-21-BM2.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 2</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2020-21-BM3.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 3</a>
                            </li>
                            <li>
                              <a href="assets/investor_relation/meetings/2020-21-BM4.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>BM Notice 4</a>
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  </Accordion>

                </div>
              </div>

            </div>
          </div>
        </section>
      </div>


    </>
  )
}
