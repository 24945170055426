import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './Search.css';
import { withTheme } from '@material-ui/core';
import { productSearch, getAllProduct } from '../../api/apiHandler';

export default function Search() {

    let { text } = useParams();

    const [productList, setProductList] = useState(null)

    useEffect(() => {
        if (text != undefined) {
            productSearch({ "search_text": text }).then((res) => {
                // console.log("------------>>>",res.data.data)
                if (res.data.code == 1) {
                    setProductList(res.data.data)
                } else {
                    setProductList([])
                }
            })
        }
    }, [text])

    if (productList == null) return <> </>

    return (
        <div className="container">
            <div className="row">
                {productList && productList?.length > 0 ? (
                    <div className="corners">
                        {
                            productList.map((item, index) => {
                                return (
                                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3" >
                                        <div className="rcorners2">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                            {item.image && item.image.length > 0 ? <img src={process.env.REACT_APP_Image_URL + item.image} alt="" style={{marginTop: "10px" }} /> : <img src="/assets/images/no-image2.jpeg" alt="" style={{ marginTop: "10px" }} />}
                                            </div>

                                            <table>
                                                <tr>

                                                    <td style={{ border: "2px solid black", borderBottom: "none", }} className='rrr2' colSpan="2">OEM Ref. No.</td>
                                                </tr>
                                                <tr>

                                                    <td style={{ border: "2px solid black", borderTop: "none", padding: "0px" }} className='rrr' colSpan="2">{item.oe_ref_no}</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "#d9d7d7" }}>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Part Description</td>
                                                    <td style={{ border: "1px solid black" }}>{item.part_description}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>SAR Part No.</td>
                                                    <td style={{ border: "1px solid black" }}>{item.sar_part_no}</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "#d9d7d7" }}>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Suitable For</td>
                                                    <td style={{ border: "1px solid black" }}>{item.suitable_for}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Alternate Part No.</td>
                                                    <td style={{ border: "1px solid black" }}>{item.altemate_part_no}</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                ) : (<h2 style={{textAlign:"center", margin:"100px 0px 50px 0px"}}>Product Not Available</h2>)
}


            </div>
        </div>
    )
}
