import React from 'react'

export default function ExchangeSubmission() {
  return (
    <div id="content">

    <section className="welcome padding-top-50 padding-bottom-50">
      <div className="container">
        <div className="heading blck">
		<div className="text-center">
          <h3>Stock Exchange Submission</h3>
          <hr/>
		  </div>

		 <ul className="dwbutton">
            <li><a href="assets/investor_relation/submission/BM Intimation 05-05-2023.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Board Meeting Intimation dtd. 05-May-2023</a></li>
            <li><a href="assets/investor_relation/submission/Letter for Closure of Trading Window.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Letter for Closure of Trading Window - Mar-2023</a></li>
            <li><a href="assets/investor_relation/submission/disclosure-under-regulation-30-sep-2022.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Disclosure under regulation 30 - Sep-2022</a></li>
            <li><a href="assets/investor_relation/submission/BM Intimation 11-11-2022.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Board Meeting Intimation dtd. 11-Nov-2022</a></li>
            <li><a href="assets/investor_relation/submission/Outcome Of BM-11-11-2022.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Outcome Of Board Meeting dtd. 11-Nov-2022</a></li>
	     </ul>

           </div>

      </div>
    </section>

  </div>
  )
}
