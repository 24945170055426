import React from 'react'

const ShareholdingPattern = () => {
	return (
		<div id="content">

			{/* Shareholding Pattern 2024-25 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2024-25</h3>
									<hr />
								</div>
							</div>

							<div className="temp" style={{ display: "flex", justifyContent: "center" }}>
								<div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2024-25/Share Holding Pattern Q1 June 30, 2024 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">June 30, 2024</h2>
											</div>
										</div>
									</a>
								</div>
								{/* <div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q2 Sep 30, 2023 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Sep 30, 2023</h2>
											</div>
										</div>
									</a>
								</div> */}
								{/* <div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q3 Dec 31, 2023 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Dec 31, 2023</h2>
											</div>
										</div>
									</a>
								</div> */}
								{/* <div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q4 Mar 31, 2024 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Mar 31, 2024</h2>
											</div>
										</div>
									</a>
								</div> */}
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2023-24 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2023-24</h3>
									<hr />
								</div>
							</div>

							<div className="temp" style={{ display: "flex", justifyContent: "center" }}>
								<div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q1 Jun 30, 2023 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Jun 30, 2023</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q2 Sep 30, 2023 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Sep 30, 2023</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q3 Dec 31, 2023 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Dec 31, 2023</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12">
									<a href="assets/investor_relation/share_holding_pattern/2023-24/Share Holding Pattern Q4 Mar 31, 2024 (FY 2023-24).pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Mar 31, 2024</h2>
											</div>
										</div>
									</a>
								</div>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2022-23 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2022-23</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2022-23/2022-23-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2022</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2022-23/2022-23-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2022</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2022-23/2022-23-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2022</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2022-23/2022-23-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2023</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2021-22 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2021-22</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2021-22/2021-22-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2021</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2021-22/2021-22-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2021</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2021-22/2021-22-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2021</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2021-22/2021-22-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2022</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2020-21 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2020-21</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2020-21/2020-21-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2020</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2020-21/2020-21-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2020</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2020-21/2020-21-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2020</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2020-21/2020-21-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2021</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2019-20 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2019-20</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2019-20/2019-20-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2019</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2019-20/2019-20-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2019</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2019-20/2019-20-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2019</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2019-20/2019-20-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2020</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2018-19 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2018-19</h3>
									<hr />
								</div>
							</div>
							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2018-19/2018-19-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2018</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2018-19/2018-19-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2018</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2018-19/2018-19-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2018</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2018-19/2018-19-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2019</h2>
										</div>
									</div>
								</a>
							</div>


						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2017-18 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2017-18</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2017-18/2017-18-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2017</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2017-18/2017-18-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2017</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2017-18/2017-18-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2017</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2017-18/2017-18-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2018</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2016-17 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2016-17</h3>
									<hr />
								</div>
							</div>
							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2016-17/2016-17-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2016</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2016-17/2016-17-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2016</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2016-17/2016-17-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2016</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2016-17/2016-17-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2017</h2>
										</div>
									</div>
								</a>
							</div>


						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2015-16 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2015-16</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2015-16/30-jun-2015.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2015</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2015-16/30-sep-2015.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2015</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2015-16/31-dec-2015.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2015</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2015-16/31-mar-2016.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2016</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2014-15 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2014-15</h3>
									<hr />
								</div>
							</div>
							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2014-15/30-jun-2014.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2014</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2014-15/30-sep-2014.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2014</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2014-15/31-dec-2014.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2014</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2014-15/31-mar-2015.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2015</h2>
										</div>
									</div>
								</a>
							</div>


						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2013-14 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2013-14</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2013-14/30-jun-2013.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2013</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2013-14/30-sep-2013.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2013</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2013-14/31-dec- 2013.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2013</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2013-14/31-mar-2014.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2014</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2012-13 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2012-13</h3>
									<hr />
								</div>
							</div>
							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2012-13/30-jun-2012.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2012</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2012-13/30-sep-2012.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2012</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2012-13/31-dec- 2012.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2012</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2012-13/31-mar-2013.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2013</h2>
										</div>
									</div>
								</a>
							</div>


						</div>

					</div>
				</div>
			</section>

			{/* Shareholding Pattern 2011-12 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Shareholding Pattern 2011-12</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2011-12/30-jun-2011.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Jun 30, 2011</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2011-12/30-sep-2011.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Sep 30, 2011</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2011-12/31-dec- 2011.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Dec 31, 2011</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/share_holding_pattern/2011-12/31-mar-2012.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Mar 31, 2012</h2>
										</div>
									</div>
								</a>
							</div>

						</div>

					</div>
				</div>
			</section>

		</div>
	)
}

export default ShareholdingPattern
