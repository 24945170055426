import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './NoticesOfMeeting.css'

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function CorporateGovernance() {
	return (
		<div id="content">

			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="heading blck">
						<div className="text-center">
							<h3>Corporate Governance </h3>
							<hr />
						</div>

						<div className="col-md-offset-2 col-md-8">



							<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingOne">
										<h4 className="panel-title">
											<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">2016-17</a>
										</h4>
									</div>
									<div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
										<div className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Jun 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Sep 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Dec 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Mar 2017</a></li>
											</ul>
										</div>
									</div>
								</div> */}
								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingTwo">
										<h4 className="panel-title">
											<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												2015-16
											</a>
										</h4>
									</div>
									<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
										<div className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/2015-16-qtr3.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2015</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2015-16-qtr3.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2016</a></li>
											</ul>
										</div>
									</div>
								</div> */}
								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingThree">
										<h4 className="panel-title">
											<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												2014-15
											</a>
										</h4>
									</div>
									<div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
										<div className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/jun_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Jun 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/sep_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Sep 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/dec_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/mar_2015.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2015</a></li>

											</ul>
										</div>
									</div>
								</div> */}
								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingFour">
										<h4 className="panel-title">
											<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
												2013-14
											</a>
										</h4>
									</div>
									<div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
										<div className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/jun_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Jun 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/sep_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Sep 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/dec_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/mar_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2014</a></li>

											</ul>
										</div>
									</div>
								</div> */}

								<Accordion className="panel-group" id="accordion" role="tablist" preExpanded={['a']} allowZeroExpanded>
									<div className="panel panel-default">
										<AccordionItem className="panel-collapse collapse in" role="tabpanel" uuid="a">
											<AccordionItemHeading className="panel-heading" role="tab">
												<AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true">
													2016-17
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Jun 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Sep 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Dec 2016</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2016-17-qtr1.pdf" target="_blank"><i className="fa fa-angle-double-right"></i> Mar 2017</a></li>
											</ul>
											</AccordionItemPanel>
										</AccordionItem>
									</div>

									<div className="panel panel-default">
										<AccordionItem className="panel-collapse collapse in" role="tabpanel">
											<AccordionItemHeading className="panel-heading" role="tab">
												<AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false">
													2015-16
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/2015-16-qtr3.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2015</a></li>
												<li><a href="assets/investor_relation/corporate_gov/2015-16-qtr3.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2016</a></li>
											</ul>
											</AccordionItemPanel>
										</AccordionItem>
									</div>

									<div className="panel panel-default">
										<AccordionItem className="panel-collapse collapse in" role="tabpanel">
											<AccordionItemHeading className="panel-heading" role="tab">
												<AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false">
													2014-2015
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/jun_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Jun 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/sep_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Sep 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/dec_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2014</a></li>
												<li><a href="assets/investor_relation/corporate_gov/mar-2015.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2015</a></li>

											</ul>
											</AccordionItemPanel>
										</AccordionItem>
									</div>

									<div className="panel panel-default">
										<AccordionItem className="panel-collapse collapse in" role="tabpanel">
											<AccordionItemHeading className="panel-heading" role="tab">
												<AccordionItemButton className="panel-title main" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false">
													2013-2014
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel className="panel-body">
											<ul className="dwbutton">
												<li><a href="assets/investor_relation/corporate_gov/jun_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Jun 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/sep_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Sep 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/dec_2013.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Dec 2013</a></li>
												<li><a href="assets/investor_relation/corporate_gov/mar_2014.pdf"><i className="fa fa-angle-double-right" target="_blank"></i> Mar 2014</a></li>

											</ul>
											</AccordionItemPanel>
										</AccordionItem>
									</div>

								</Accordion>


							</div>

						</div>







					</div>

				</div>
			</section>

		</div>
	)
}
