import React from 'react'

export default function CompanyPolicies() {
  return (
    <div id="content">

    <section className="welcome padding-top-50 padding-bottom-50">
      <div className="container">
        <div className="heading blck">
		<div className="text-center">
          <h3>Company Policies </h3>
          <hr/>
		  </div>

		  <ul className="dwbutton">
		 <li><a href="assets/investor_relation/policies/CSR-Policy.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  CSR Policy</a></li>
		 <li><a href="assets/investor_relation/policies/Nomination Remuneration Policy.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Nomination & Remuneration Policy</a></li>
		 <li><a href="assets/investor_relation/policies/Policy Determination Of Materiality.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy Determination Of Materiality</a></li>
		 <li><a href="assets/investor_relation/policies/Policy On Sexual Harrasment.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy On Sexual Harrasment </a></li>
		 <li><a href="assets/investor_relation/policies/Policy Preservation Of Documents.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy Preservation Of Documents </a></li>
		 <li><a href="assets/investor_relation/policies/Whistle Blower Policy.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Whistle Blower Policy </a></li>
		 <li><a href="assets/investor_relation/policies/Policy-Familiarisation.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Familiarization Programme for Independent Directors </a></li>
		 <li><a href="assets/investor_relation/policies/Policy on Materiality of Related Party Transactions and Dealing with RPT.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Materiality of Related Party Transactions and Dealing with RPT </a></li>
		 <li><a href="assets/investor_relation/policies/Note for physical shareholder SAR.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Note For Shareholders Holding Physical Shares </a></li>
		 <li><a href="assets/investor_relation/policies/Policy on Materiality of RPT.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy on Materiality of RPT </a></li>
		 <li><a href="assets/investor_relation/policies/Policy on Preservation of documents.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy on Preservation of Documents </a></li>
		 <li><a href="assets/investor_relation/policies/Policy on Materiality of Events.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Policy on Materiality of Events </a></li>
		 <li><a href="assets/investor_relation/policies/Authorized KMP for Determining materiality of Events.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>  Authorized KMP for Determining materiality of Events </a></li>
	     </ul>

           </div>

      </div>
    </section>

  </div>
  )
}
