import React from 'react'
import './Quality.css'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Quality = () => {
  return (

    <div id="content">
      <section className="welcome padding-top-50 padding-bottom-50">
        <div className="container">
          <div className="row" >
            <div className="col-lg-6 col-md-6 col-sm-6 text-center">
              <div className="img-thumbnail">
                <Zoom>
                  <img src="assets/images/IATF - SAR.jpg" alt=""  />
                </Zoom>
                {/* <div className="Zoomtex">Click To Zoom</div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 text-center test">
              <div className="img-thumbnail">
                <Zoom>
                  <img src="assets/images/Certificate_new.jpg" alt="" />
                </Zoom>
                {/* <div className="Zoomtex">Click To Zoom</div> */}
              </div>
            </div>
            <div className="col-lg-12" style={{ marginTop: "20px" }}>
              <div className="text-justify">
                <p>All parts are manufactured and tested as per the German Specification (DIN standard) and majority of the parts are self-certified by our OEM customers and are directly used at their assembly line. </p><br />
                <p>We carry out statistical process control studies for better process capabilities and we emphasize more on the defect prevention, rather than defect detection.</p><br />
                <p>Our in-house standard room is fully equipped to calibrate the monitoring and measuring devices used in the manufacturing processes. We have our in-house chemical and metallurgical laboratory to cater to all our needs in the chemical & metallurgical analysis.</p><br />
                <p>Our range of testing machines include computerized Gear helix & Profile tester and Gear roll tester (Maag & Mahr) besides Helix measuring and hob testing machine, Material hardness testers (BHN & HRC) & other standard measuring instruments.</p><br />

              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  )
}

export default Quality
