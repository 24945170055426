import React from 'react'

const NewspaperPublication = () => {
  return (
    <div id="content">

      <section className="welcome padding-top-50 padding-bottom-50">
        <div className="container">
          <div className="heading blck">
            <div className="text-center">
              <h3>Newspaper Publication</h3>
              <hr />
            </div>

            <ul className="dwbutton">
              <li><a href="assets/investor_relation/newspaper/Quarter and year ended on 31st March, 2024.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter and year ended on 31st March, 2024</a></li>

              <li><a href="assets/investor_relation/newspaper/Quarter ended on 31st December, 2023.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 31st December, 2023</a></li>

              <li><a href="assets/investor_relation/newspaper/Quarter ended on 30th September, 2023.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 30th September, 2023</a></li>

              <li><a href="assets/investor_relation/newspaper/Quarter ended on 30th June, 2023.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 30th June, 2023</a></li>

              <li><a href="assets/investor_relation/newspaper/News Paper Publication FY 2022-23 Results.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 31st March, 2023</a></li>

              <li><a href="assets/investor_relation/newspaper/1. SAPL Q-3 Results.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 31st December, 2022</a></li>

              <li><a href="assets/investor_relation/newspaper/2. SAPL Q-2 Results.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter and half year ended on 30th September,2022</a></li>

              <li><a href="assets/investor_relation/newspaper/3. SAPL Q-1 Results.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Submission of Newspaper Copy - Quarter ended on 30th June, 2022</a></li>

              <li><a href="assets/investor_relation/newspaper/4. AGM Notice-Mar-2022.pdf" target="_blank"><i className="fa fa-angle-double-right"></i>Publication of Notice of 35th AGM for the financial year ended on 31st March, 2022</a></li>
            </ul>

          </div>

        </div>
      </section>

    </div>
  )
}

export default NewspaperPublication
