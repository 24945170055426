import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import './Product.css';
import { getProduct } from '../../api/apiHandler';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function Product() {


    let { id } = useParams();

    // window.location.reload();
    const [productList, setProductList] = useState(null)

    useEffect(() => {
        if (id != undefined) {
            getProduct({ "category_id": id }).then((res) => {
                // console.log("------------>", id, res.data.data)
                if (res.data.code == 1) {
                    setProductList(res.data.data)
                } else {
                    setProductList([])
                }
            })
        }
    }, [id])


    if (productList == null) return <> </>

    return (
        <>

            <div className="container">
                <div className='row'>
                    {productList?.map((item, index) =>
                        index < 1 &&
                        <div className="col-lg-12 head">
                            <h2 className='name'>{item.name.toUpperCase()}</h2>
                            <hr />
                        </div>
                    )
                    }
                </div>

                <div className="row">
                    <div className="corners">

                        {
                            productList.map((item, index) => {
                                return (
                                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3" >
                                        <div className="rcorners2">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Zoom>
                                                    <img src={process.env.REACT_APP_Image_URL + item.image} alt="" style={{ marginTop: "10px" }} />
                                                </Zoom>
                                                {/* {item.image && item.image.length > 0 ? (<img src={process.env.REACT_APP_Image_URL + item.image} alt="" style={{ marginTop: "10px" }} />) : (<img src="/assets/images/no-image2.jpeg" alt="" style={{ marginTop: "10px" }} />)} */}

                                            </div>

                                            <table>
                                                <tr>

                                                    <td style={{ border: "2px solid black", borderBottom: "none", }} className='rrr2' colSpan="2">OEM Ref. No.</td>
                                                </tr>
                                                <tr>

                                                    <td style={{ border: "2px solid black", borderTop: "none", padding: "0px" }} className='rrr' colSpan="2">{item.oe_ref_no}</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "#d9d7d7" }}>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Part Description</td>
                                                    <td style={{ border: "1px solid black" }}>{item.part_description}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>SAR Part No.</td>
                                                    <td style={{ border: "1px solid black" }}>{item.sar_part_no}</td>
                                                </tr>
                                                <tr style={{ backgroundColor: "#d9d7d7" }}>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Suitable For</td>
                                                    <td style={{ border: "1px solid black" }}>{item.suitable_for}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: "1px solid black", width: "40%" }}>Alternate Part No.</td>
                                                    <td style={{ border: "1px solid black" }}>{item.altemate_part_no}</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                </div>
            </div>

            <marquee width="100%" direction="left" height="20px" style={{ backgroundColor: "#f8aa48" }}>
                Our parts are not original but perfectly interchangable with O.E.M. ones. All original equipment manufactuer's names, numbers, symbols and descriptions used in this page are for reference purpose only.
            </marquee>

        </>
    )
}
