import React from 'react'

export default function WhoWeAre() {
	// eslint-disable-next-line
  return (
		<div id="wrap">
			<section className="sub-bnr" style={{backgroundImage:'url(./assets/images/sub-bnr-bg.jpg) no-repeat'}}>
				<div className="position-center-center">
					<div className="container">
						<h4>About Us</h4>
					</div>
				</div>
				<div className="bread">
					<div className="container">
						<h6>Welcome to SAR Auto Products Ltd </h6>
					</div>
				</div>
			</section>
			<div id="content">
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="heading blck text-center">
						<h3>Who we are</h3>
						<hr/>
						<div className="text-justify">
						<p>We are one of the leading manufacturers of quality assured gears, gear boxes and other transmission components in Rajkot, Gujarat-India, since last 20+ years. SAR Auto Products Limited owes its inception in 1987 to its Chairman Mr. Ramesh D. Virani, and it was incorporated as a Public limited company in 1996.
	</p>
	<p>We produce gears conforming to German specification DIN 7 to DIN 9 class of accuracy. We are certified to ISO/TS 16949:2009 quality system requirements, from TUV Suddeutschland, in the year 2008. We achieve various milestones by way of technical competency with years of experience in gears, continuous improvement in the processes and systems, technical up gradation from time to time, positive approach of management as well as employees, multidisciplinary approach in the planning and problem solving, value addition & clearly defined organization structure with measurable time bound objectives.
	</p>
	
	
	<div className="heding1"> <abbr className="blue_color">We have a greater advantage of being located in Rajkot (Gujarat) as:</abbr></div>
	<p>It is a hub for engineering industries, enabling us to offer the customers more range of products and services in a faster and at the most competent way. With, many OEM approved raw material suppliers and heat treatment service providers stationed here, we have a competitive advantage in providing the services, in the most efficient and effective way to delight our valued customers. As Rajkot being popular for its skilled labour availability & labour friendly environment, we hold the reputation of being the most reliable, at any point of time.
	
	Our gears are used in automobiles, machine tool industries and printing machineries. We are ready and equipped to manufacture any type of gear for anyone, anywhere in the world!
	</p>
	
	<div className="heding1"> <abbr className="blue_color">Future Plan:</abbr></div>
	<p>We are on the threshold of manufacturing the following products:</p>
	
	
	<p>{'=>'} Assembly of Gear boxes for automobiles and machine tools.</p>
	<p>{'=>'} With the best of technical expertise, financial acumen, marketing experience and managerial skills at hand, we actively seek new clients in India and abroad.</p>
	<p>{'=>'} We welcome foreign collaborations in technical knowhow, with buyback arrangements, for manufacture of Gear boxes, Geared motors, Ground Gears and other similar components.</p>
	<p> Auto Parts Today, Airplanes Tomorrow!</p>
	
			</div>
		</div>
		</div>
			</section>
			</div>
		</div>
	
  )
}
