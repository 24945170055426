import React from 'react'

export default function CompanyInformation() {
  return (  
		<>
    <section className="sub-bnr" style={{background: 'url(assets/images/sub-bnr-bg.jpg) no-repeat'}}>
        <div className="position-center-center">
          <div className="container">
            <h4>Investors</h4>
          </div>
        </div>
        <div className="bread">
          <div className="container">
            <h6>Welcome to SAR Auto Products Ltd</h6>
          </div>
        </div>
      </section>

      <div id="content">
        <section className="welcome padding-top-50 padding-bottom-50">
          <div className="container">
            <div className="heading blck">
              <div className="text-center">
                <h3>Company Information</h3>
                <hr />
              </div>

              <div className="col-md-12">
                <div className="col-md-6">
                  <div className="informtonmain">
                    <h5>Board of Directors:</h5>

                    <div className="sacondtex">MR. RAMESHKUMAR D VIRANI</div>
                    <div className="graycol">(Chairman & Managing Director)</div>

                    <div className="sacondtex">MR. SHREYAS R VIRANI</div>
                    <div className="graycol">(Managing Director)</div>

                    <div className="sacondtex">MR. ISSACTHOMAS C KAVUNKAL</div>
                    <div className="graycol">(Independent Director)</div>

                    <div className="sacondtex">MRS. AARTI C SODHA</div>
                    <div className="graycol">(Independent Director Women Director)</div>

                    {/* <div className="sacondtex">MRS. BHUMIKABEN V. KALARIYA</div>
                    <div className="graycol">(Independent Director)</div> */}

                    <div className="sacondtex">Ms. Zalak Kamlesh Upadhyay</div>
                    <div className="graycol">
                      (Company Secretary, B.Com) <br />
                      Contact No.: +91 7990918355
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <aside className="sidebar">
                    <div className="single contact-info">
                      <h3 className="side-title">
                        Registrar & Share Transfer Agent: <br />
                        <small>Link Intime India Private Limited (Ahmedabad Branch) </small>
                      </h3>
                      <ul className="list-unstyled">
                        <li>
                          <div className="icon"><i className="fa fa-map-marker"></i></div>
                          <div className="info">
                            <p>
                              5th Floor, 506 to 508,<br />
                              Amarnath Business Centre - 1 (ABC-1),<br />
                              Beside Gala Business Centre,<br />
                              Nr. St. Xavier's College Corner, <br />
                              Off C. G. Road, Ellisbridge, Ahmedabad - 380 006
                            </p>
                          </div>
                        </li>

                        <li>
                          <div className="icon"><i className="fa fa-phone"></i></div>
                          <div className="info"><p>079 26465179&nbsp;</p></div>
                        </li>

                        {/* <li>
                          <div className="icon"><i className="fa fa-fax"></i></div>
                          <div className="info"><p>033 22484787&nbsp;</p></div>
                        </li> */}

                        <li>
                          <div className="icon"><i className="fa fa-envelope"></i></div>
                          <div className="info">
                            <p>
                              ahmedabad@linkintime.co.in<br />
                              www.linkintime.co.in
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="single contact-info">
                      <h3 className="side-title"><small>Grievance Redressal & Handling Investor Grievances:</small></h3>

                      <ul className="list-unstyled">
                        <li>
                          <div className="icon"><i className="fa fa-comments"></i></div>
                          <div className="info">
                            <p>
                              Ms. Zalak Kamlesh Upadhyay<br />
                              Mobile No. +91-7990918355<br />
                              Email Id : sapl@sarautoproductsltd.com
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
			</>
  )
}
