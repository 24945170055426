import React from 'react'

export default function Infrastructure() {
	// eslint-disable-next-line
  return (
    <div id="wrap">
			<section className="sub-bnr" style={{backgroundImage:'url(./assets/images/sub-bnr-bg.jpg) no-repeat'}}>
				<div className="position-center-center">
					<div className="container">
						<h4>About Us</h4>
					</div>
				</div>
				<div className="bread">
					<div className="container">
						<h6>Welcome to SAR Auto Products Ltd </h6>
					</div>
				</div>
			</section>
			<div id="content">
    <section class="welcome padding-top-50 padding-bottom-50">
      <div class="container">

        <div class="heading blck text-center">
          <h3>Infrastructure</h3>
          <hr/>
          <div class="text-justify">
          <p>Our strength is our people. A panel of 5 Senior Engineers provide top class technical backup. Under their able supervision 25 skilled technicians work at SAR around the clock. The facility comprises of 2 workshops of sizes 1600 sq. mts. and 1200 sq. mts. with a complete standby power for the entire premises.
  </p><br/>
  <p>We are constantly undergoing expansion and introducing newer technologies and equipment. Computerization in every facet of operations is the key to our manufacturing success. The manufacturing processes are : Turning on CNC turning centers, Gear cutting on CNC Hobbing, Shaping & Straight Bevel generating machines, Gear shaving (with inprocess gauging system), Gear Honning, Broaching (horizontal & Vertical), Tooth rounding / Chamfering, Gear cleaning (Shot Blasting), Milling and Grinding: Internal (CNC), External, Surface & Root with Inprocess gauging system and CNC Hard Turning. SAR is also equipped for Heat treatment processes like : Gas Carburizing, Induction Hardening, Salt bath HT, Nitriding, Annealing & Normalizing. We also have a TOOL ROOM for manufacture of jigs, fixtures, etc.
</p><br/>
          </div>

           </div>


          <table class="table table-bordered table-hover table-striped">
  <thead>
    <tr>
      <th colspan="5" class="tablebg">Manufacturing Facilities :</th>
    </tr>
  </thead>
  <tbody>

    <tr>
      <th>Turning</th>
      <td>Cleaning</td>
    </tr>
	<tr>
      <th>Gear Cutting</th>
      <td>Grinding</td>
    </tr>
	<tr>
      <th>Tooth Rounding Machines</th>
      <td>Cutter Resharpening</td>
    </tr>
	<tr>
      <th>Gear Shaving, Honning</th>
      <td>Stand by Power Supply Facility</td>
    </tr>
	<tr>
      <th colspan="2">Milling</th>
    </tr>


  </tbody>
</table>

           </div>

    </section></div></div>
  )
}
