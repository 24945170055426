import React from 'react'

const QuarterlyResults = () => {
	return (
		<div id="content">
			{/* Quarterly Results for Financial Year 2024-25 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2024-25</h3>
									<hr />
								</div>
							</div>


							<div className="temp" style={{ display: "flex", justifyContent: "center" }}>
								{/* <div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q4-Full-Year-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q4 and Full Year</h2>
											</div>
										</div>
									</a>
								</div> */}
								{/* <div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q3-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q3 Results</h2>
											</div>
										</div>
									</a>
								</div> */}
								{/* <div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q2-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q2 Results</h2>
											</div>
										</div>
									</a>
								</div> */}
								<div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2024-25/Financial-Results-Q1-FY-2024-25.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q1 Results</h2>
											</div>
										</div>
									</a>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2023-24 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2023-24</h3>
									<hr />
								</div>
							</div>


							<div className="temp" style={{ display: "flex", justifyContent: "center" }}>
								<div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q4-Full-Year-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q4 and Full Year</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q3-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q3 Results</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q2-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q2 Results</h2>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-3 mbr-col-md-12 test">
									<a href="assets/investor_relation/financial_results/2023-24/Financial-Results-Q1-FY-2023-24.pdf" target="_blank">
										<div className="wrap">
											<div className="ico-wrap">
												<span className="mbr-iconfont fa fa-file-pdf-o"></span>
											</div>
											<div className="text-wrap vcenter">
												<h2 className="display-5">Q1 Results</h2>
											</div>
										</div>
									</a>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2022-23 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2022-23</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2022-23/2022-23-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2022-23/2022-23-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2022-23/2022-23-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>



							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2022-23/2022-23-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2021-22 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2021-22</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2021-22/2021-22-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2021-22/2021-22-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2021-22/2021-22-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>



							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2021-22/2021-22-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2020-21 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2020-21</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2020-21/2020-21-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2020-21/2020-21-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2020-21/2020-21-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>



							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2020-21/2020-21-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2019-20 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2019-20</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2019-20/2019-20-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2019-20/2019-20-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2019-20/2019-20-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>



							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2019-20/2019-20-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>

						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2018-19 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2018-19</h3>
									<hr />
								</div>
							</div>
							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2018-19/2018-19-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2018-19/2018-19-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2018-19/2018-19-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2018-19/2018-19-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2017-18 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2017-18</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2017-18/2017-18-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2017-18/2017-18-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2017-18/2017-18-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2017-18/2017-18-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2016-17 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2016-17</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2016-17/2016-17-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2016-17/2016-17-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2016-17/2016-17-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2016-17/2016-17-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2015-16 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2015-16</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2015-16/Q4 and Full Year FY2015-16 Financial Results.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2015-16/2015-16-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2015-16/2015-16-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2015-16/2015-16-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2014-15 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2014-15</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2014-15/2014-15-qtr4.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2014-15/2014-15-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2014-15/2014-15-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2014-15/2014-15-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2013-14 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2013-14</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2013-14/2013-14-qtr4-full.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2013-14/2013-14-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2013-14/2013-14-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2013-14/2013-14-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2012-13 */}
			<section className="welcome padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2012-13</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2012-13/2012-13-qtr4-full.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2012-13/2012-13-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2012-13/2012-13-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2012-13/2012-13-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quarterly Results for Financial Year 2011-12 */}
			<section className="welcome sectonbg padding-top-50 padding-bottom-50">
				<div className="container">
					<div className="col-md-12">
						<div className="row mbr-justify-content-center">
							<div className="heading blck">
								<div className="heading blck text-center">
									<h3>Quarterly Results for Financial Year 2011-12</h3>
									<hr />
								</div>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2011-12/2011-12-qtr4-full.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q4 and Full Year</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2011-12/2011-12-qtr3.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q3 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2011-12/2011-12-qtr2.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q2 Results</h2>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-3 mbr-col-md-12">
								<a href="assets/investor_relation/financial_results/2011-12/2011-12-qtr1.pdf" target="_blank">
									<div className="wrap">
										<div className="ico-wrap">
											<span className="mbr-iconfont fa fa-file-pdf-o"></span>
										</div>
										<div className="text-wrap vcenter">
											<h2 className="display-5">Q1 Results</h2>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>


		</div>
	)
}

export default QuarterlyResults
