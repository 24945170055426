import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Catalogues() {
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);
  return (
    <>
      {/* <Header /> */}
      {/* {loading ? (
        <div className="loader-container" >
          <div className="loader-icon">
            <img src='/assets/images/logo.png' alt="" />
            <img src="/assets/images/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <> */}
          <div id="wrap">
            <section className="sub-bnr" style={{ backgroundImage: 'url(./assets/images/sub-bnr-bg.jpg) no-repeat' }}>
              <div className="position-center-center">
                <div className="container">
                  <h4>Catalogues</h4>
                </div>
              </div>
              <div className="bread">
                <div className="container">
                  <h6>Welcome to SAR Auto Products Ltd </h6>
                </div>
              </div>
            </section>
            <div id="content">
              <section className="welcome padding-top-50 padding-bottom-50">
                <div className="container">

                  <div className="heading blck">

                    <div className="text-justify">
                      <p>Sar Auto Products Limited manufactures auto components comprising of automobile parts, Synchronizer Rings, Cones, Sleeves and Hubs like Spur, Helical, Straight Bevel, Sprockets along with Transmission Spline Shaft, Couplings and Power Takeoff Clutches which are used for Transmission, Engine and Differential gear boxes.
                      </p>
                      <p>We have battery of CNC & VMC machines and we can manufacture machined parts upto 480mm dia. You can send us the samples/drawings and we shall submit our samples for your approval. Meanwhile, Kindly explore our Brochure for detailed product information.</p>

                    </div>
                  </div>
                </div>
              </section>

              <div id="content">
                <div className="promos padding-top-50 padding-bottom-50">
                  <div className="row">
                    <div className="container">
                      <div className="col-md-6 emergency-text">
                        <h4>Helpline : +91 281 2374726 (Mon-Sat: 9:30 to 19:00)</h4>
                        <p className="text-white font-ubuntu">
                          CATALOGUE - SAR AUTO PRODUCTS LTD - RAJKOT (GUJARAT) INDIA
                        </p>
                      </div>

                      <div className="col-md-3 text-right"><a href="assets/investor_relation/SAR-AUTO-PRODUCTS-LTD-BROCHURE.pdf" className="btn btn-blank margin-top-20" target="_new">Download Brochure</a> </div>


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          {/* <Footer /> */}
        {/* </>
      )} */}
    </>
  )
}
